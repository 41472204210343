import request from '.'

interface IAddIntegrationPayload {
  details: string
  integration_name: string
  integration_type: string
  service_id: string
  service_name: string
  status: 'Enabled' | 'Disabled'
  action_type: 'add_integration' | 'edit_integration'
}

interface PagerdutyAppPayloadI {
  app_name: 'pagerduty-app'
  service_id: string
  service_name: string
}

interface SlackAppPayloadI {
  app_name: 'slack-app'
  service_id: 'ALL'
  service_name: 'NA'
}


interface SaveServicePayloadI {
  service_id: string,
  integration_id: string
  integration_name: string
  pd_services: {
    pd_service_name: string
    status: number
  }[]
}

interface fetchIntegrationListsParamsI {
  endpoint: 'connected_integrations'
  keyword: string,
  pageid: number,
  items: number,
  clients: string,
  integrations: string,
  enabled: 0 | 1,
  sorting_col: string,
  sorting_type: 'asc' | 'desc' | '',
}

export const addIntegration = (payload: IAddIntegrationPayload) => {
  return request.post(`/extensions/add-extension`, payload)
}

export const fetchIntegrationLists = (params: fetchIntegrationListsParamsI) => {
  return request(`/extensions/list`, {
    params
  })
}

export const fetchIntegrationById = (id: string) => {
  return request('/extensions', {
    params: {
      endpoint: 'get_integration',
      integration_id: id
    }
  })
}

export const deleteIntegrations = (id: string) => {
  return request.post('/extensions/add-extension', {
    action_type: 'delete_integration',
    integration_id: id
  })
}

export const toggleIntegrationStatus = (id: string) => {
  return request.post(`/extensions/add-extension`, {
    action_type: 'toggle_integration_status',
    integration_id: id
  })
}

export const fetchPagerdutyServices = (id: string) => {
  return request(`/extensions`, {
    params: {
      endpoint: 'get_integration_services',
      integration_id: id
    }
  })
}

export const savePagerdutyServices = (payload: SaveServicePayloadI) => {
  return request.post(`/extensions/add-extension`, {
    action_type: 'set_pd_services',
    ...payload
  })
}

export const installPagerdutyApp = (payload: PagerdutyAppPayloadI) => {
  return request.post(`/extensions/install-app`, payload)
}

export const installSlackApp = (payload: SlackAppPayloadI) => {
  return request.post(`/extensions/install-app`, payload)
}