import React, { useEffect } from 'react'
import styled from 'styled-components'
import { withRouter, Link } from 'react-router-dom'
import queryString from 'query-string'
import NCallLogoSVG from 'assets/img/nCall-logo.svg'
import UserDropdownMenu from './user-dropdown-menu'
import AppNotification from 'pages/app-notification'
import { useDispatch } from 'react-redux'
import { SearchIcon } from 'components/ui/icons'
import {
  required_params, setFilters,
  setNeedRefresh, setReload
} from 'store/reducers/incidents.reducer'
import { useAuth } from 'common/hooks/use-simple-auth'
import {
  ACCOUNT_OWNER,
  ADMIN
} from 'common/utils/permissionFunctions'
import { getUserInfoByEmail } from 'services/userRoles.service'
import { ENV, SOCKET_URL } from 'common/constants'
import { setStatus } from 'store/reducers/notification.reducer'
import WhatsNew from './WhatsNew'
import messaging from '../../../firebase'
import { notification } from '../../ui/toast/toast'
import NotificationStatus from '../../notificaiton-status'
import NCInput from '../../nCall/NCinput'
import 'assets/css/nc-css/nc-header.css'
import './header.css'

const CommonSearch = styled.div`
    padding: 10px;
    margin-left: 10rem;    
    .ncc-input-group {      
     min-width: 400px; 
      input {
        background: #3d4a56;
        border: none;
        border-radius: 5px;
        height: 38px;
        color: #fff
        &::placeholder {
          color: #fff;
        }
      }  
    }   
`

interface Props {
}

export const Header: React.FC<Props> = () => {
  const {user, setUser, logout} = useAuth() || {}
  const dispatch = useDispatch()
  const params = queryString.stringify(required_params)

  // const isMatch = useCallback(
  //   (path: string, isExact: boolean = false) => {
  //     const match = matchPath(location.pathname, path)
  //     return isExact ? match && match.isExact : match  
  //   },
  //   [location.pathname]
  // )

  const getRefreshedUserInfo = async () => {
    if (user.email) {
      try {
        const resp = await getUserInfoByEmail(user.email)
        const { data: userInfo} = resp.data
        setUser({...user, ...userInfo})
      } catch (err) {
        logout()
      }
    } else {
      logout()
    }
  }

  const getRedirectionLink = () => {
    if (user.user_role.includes(ADMIN) || user.user_role.includes(ACCOUNT_OWNER)) {
      return `/incidents?${params}`
    }
    return '/dashboard'
  }

  const onOpenSocket = (evt: any) => {
    console.log('Socket connected:', evt?.type)
  }

  const onMessage = (evt: any) => {
    const data = JSON.parse(evt.data || '{}')
    console.log('On message Recieved', evt.data)

    if (data.type === 'new_incident') {
      dispatch(setStatus(data))
      document.title = data.notification_count > 0 ? `(${data.notification_count}) nCall` : 'nCall'
    }
    if (data.type === 'board_update' && window.location.pathname === '/incidents') {
      dispatch(setNeedRefresh(true))
    }
    // if (data.type === 'incident_feeds' && window.location.pathname === '/incidents') {
    //   dispatch(updateMyOpenIncidents(data))
    // }
  }

  const handleOnRefresh = () => {
    dispatch(setReload(true))
  }

  useEffect(() => {
    dispatch(setFilters({
      userlogged: user.email
    }))
    getRefreshedUserInfo()
    //adding script for prod env

    if (ENV === 'production') {
      try {
        const script = document.createElement('script')
        script.innerHTML = `window.$sleek=[];window.SLEEK_PRODUCT_ID=486871397;(function(){d=document;s=d.createElement("script");s.src="https://client.sleekplan.com/sdk/e.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`
        document.head.appendChild(script)
      } catch (err) {
        console.error('Error while adding script to production.')
      }
    }

    if (ENV === 'prod') {
      try {
        const script = document.createElement('script')
        script.async = true
        script.innerHTML = `window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
      heap.load("332467686");`
        document.head.appendChild(script)
      } catch (err) {
        console.error('Error adding custom script to head', err)
      }
    }

    if (ENV === 'dev') {
      const script = document.createElement('script')
      script.async = true
      script.innerHTML = `window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
      heap.load("2510304779");`
      document.head.appendChild(script)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {

    function connect() {
      const ws = new WebSocket(`${SOCKET_URL}?user_id=${user.user_id}`)
      ws.onopen = onOpenSocket
      ws.onmessage = onMessage
      ws.onclose = function (e) {
        console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason)
        setTimeout(function () {
          connect()
        }, 1000)
      }
      ws.onerror = function (err) {
        console.error('Socket encountered error: ', err.type, 'Closing socket')
        ws.close()
      }
    }

    connect()

    //receive push notification when tab is focused (foreground notification)
    messaging.onMessage((payload) => {
      console.log('Push message received. ', payload)
      const {data} = payload
      const body = data['pinpoint.notification.body']
      const title = data['pinpoint.notification.title']
      const url = data['pinpoint.url']
      notification.push({
        title: title,
        message: body,
        actionText: 'View details',
        onActionClick: () => window.open(url, '_blank')
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <header id='header'>
      <div className='header-inner'>
        <div className='flex_row'>
          <div className='header-left'>
            <div id='logo'>
              <Link to={getRedirectionLink()}>
                <img src={NCallLogoSVG} alt='nCall'/>
              </Link>
            </div>
          </div>

          <div className='header-middle'>
            <CommonSearch style={{ visibility: 'hidden' }}>
              <NCInput
                placeholder='search'
                prefix={<SearchIcon/>}
              />
            </CommonSearch>
          </div>
          <div className='header-right'>
            <ul className='header-actions'>
              <li>
                <WhatsNew/>
              </li>
              <li>
                <AppNotification onRefresh={handleOnRefresh}/>
              </li>
            </ul>

            <div className='nav-dropdown user-dropdown'>
              <UserDropdownMenu/>
            </div>
          </div>
        </div>
      </div>
      <NotificationStatus/>
    </header>
  )
}

export default withRouter(Header)
