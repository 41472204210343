import React from 'react'
import cx from 'classnames'
import {
  AscendingSortIcon,
  DefaultSortIcon,
  DescendingSortIcon
} from '../icons'
import './table.css'

interface IColumn {
  key: string;
  dataIndex: string;
  title: string;
  all?: boolean
  icon?: React.ReactElement | string
  isSeverityColumn?: boolean
  isSorting?: boolean
  render?: (value: any) => React.ReactElement | string
}

interface TableProps {
  columns: IColumn[],
  dataRows?: any;
  customBody?: boolean
  pagination?: React.ReactElement | null
  onSelectAll?: (checked: boolean) => void
  checkbox?: React.ReactElement
  className?: string
  sorted_by?: string
  noDataComponent?: any
  wrapperClass?: string
  sorted_type?: string
  onSorting?: (key: string, orderBy: 'asc' | 'desc') => void
  v2?: boolean
}

export const Table: React.FC<TableProps> = props => {

  const {
    columns = [],
    dataRows = [],
    customBody = false,
    pagination = null,
    checkbox = null,
    className,
    sorted_by,
    wrapperClass,
    noDataComponent,
    sorted_type,
    v2,
    onSorting = () => {
    }
  } = props

  const renderByAll = (data: any, column: IColumn): any => {
    if (column.all && column.render) {
      return column?.render(data)
    } else {
      // @ts-ignore
      return column?.render(data[column.dataIndex])
    }
  }

  const renderTableBody = () => {
    if (customBody)
      return props.children

    if (dataRows.length === 0 && noDataComponent) {
      return noDataComponent
    }

    if (dataRows.length === 0)
      return (
        <tr>
          <td colSpan={columns.length}>
            <div className='data'>
              No data founds.
            </div>
          </td>
        </tr>
      )

    return dataRows.map((data: any) => {
      return (
        <tr key={data.id}>
          {columns.map((c) => {
            if (c.render) {
              return (
                <td key={c.key}>
                  <div className='data'>
                    {renderByAll(data, c)}
                  </div>
                </td>
              )
            }
            return (
              <td key={data.id + c.key}>
                <div className='data'>
                  {data[c.dataIndex]}
                </div>
              </td>
            )
          })}
        </tr>
      )
    })
  }

  const onApplySorting = (key: string) => {
    if (key === sorted_by && sorted_type === 'asc') {
      onSorting(key, 'desc')
    } else if (key === sorted_by && sorted_type === 'desc') {
      onSorting(key, 'asc')
    } else {
      onSorting(key, 'asc')
    }
  }

  const renderSortIcon = (c: any) => {

    if (sorted_by === c.dataIndex && sorted_type === 'desc') {
      return (
        <i className='table-sort-icon'>
          <DescendingSortIcon/>
        </i>
        // <span className='up-down-icon'/>
      )
    }

    if (sorted_by === c.dataIndex && sorted_type === 'asc') {
      return (
        <i className='table-sort-icon'>
          <AscendingSortIcon/>
        </i>
        // <span className='up-down-icon'/>
      )
    }

    if (c.isSorting)
      return (
        <i className='table-sort-icon'>
          <DefaultSortIcon/>
        </i>
        // <span className='up-down-icon'/>
      )
    return null
  }

  return (
    <div className='nc-table2'>
      <div
        className={
          cx(`nc-table2-wrapper ${wrapperClass}`, {
            'table-v2': v2
          })
        }
      >
        <table className={className}>
          <thead>
          <tr>
            {columns.map((c) => {
              if (c.key === 'checkbox') {
                return (
                  <th
                    className={`checkbox-action ${dataRows.length === 0 ? 'empty' : ''}`}
                    key={c.key}
                  >
                    <div className='data'>
                      {checkbox}
                    </div>
                  </th>
                )
              }
              if (c.key === 'expand_collapse') {
                return (
                  <th
                    className={`expand_collapse`}
                    key={c.key}
                  >
                    <div className='data' />
                  </th>
                )
              }

            return (
            <th key={c.key}>
              <div
                className={cx('data', {
                  sorting: c.isSorting,
                  'i-severity-col': c.isSeverityColumn
                })}
                onClick={c.isSorting ? () => onApplySorting(c.dataIndex) : () => {
                }}
              >
                {c.title}
                {renderSortIcon(c)}
              </div>
              {c.icon && <span className='icon-div'>{c.icon}</span>}
            </th>
            )
            })}
          </tr>
          </thead>
          <tbody>
          {renderTableBody()}
          </tbody>
        </table>
        {pagination}
      </div>
    </div>
  )
}

export default Table
