const ENV = process.env.REACT_APP_STAGE

console.log('process.env.API_URL', process.env.API_URL)

let PUBLIC_URL = 'http://api.dev.ncall.io/'
let SOCKET_URL = 'wss://puixduivsl.execute-api.us-east-1.amazonaws.com/dev'
// OLD URL
// let SOCKET_URL = 'wss://0t6dffe855.execute-api.us-east-1.amazonaws.com/dev'
let QUICKSIGHT_URL =
  'https://1i6rz1avpg.execute-api.us-east-1.amazonaws.com/dev'
let vapidKey =
  'BDzBAIDSI5yzwyTSZp92DeFGaBiZhRGm41KtLzvbuJroh1SKVt04T0ViOZdGSLbe2zKkzeDmDWTnYpaJVqe5hNs'
let DASHBOARD_PLOTY_URL = 'https://dashboard.ncall.io/ncall-analytics'

const firebaseConfig: any = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

const isStag = window.location.host.indexOf('stag.ncall') > -1
const isDemo = window.location.host.indexOf('demo.ncall') > -1
const isCert = window.location.host.indexOf('cert.ncall') > -1
const isProduction = window.location.host.indexOf('app.ncall') > -1
// const isDev = window.location.host.indexOf('dev.ncall') > -1

if (isProduction) {
  PUBLIC_URL = 'https://api-prod.ncall.io'
  SOCKET_URL = 'wss://inapp-notifications-prod.ncall.io'
  QUICKSIGHT_URL = 'https://quicksight.ncall.io'
  DASHBOARD_PLOTY_URL = 'https://dashboard.ncall.io/ncall-analytics'
} else if (isCert) {
  DASHBOARD_PLOTY_URL = 'https://dashboard.ncall.io/ncall-analytics'
  PUBLIC_URL = 'https://api.cert.ncall.io'
  SOCKET_URL = 'wss://yyyyaz13g4.execute-api.us-east-1.amazonaws.com/uat'
} else if (isDemo) {
  DASHBOARD_PLOTY_URL = 'https://dashboard.ncall.io/ncall-analytics'
  PUBLIC_URL = 'https://uehvscu4t4.execute-api.us-east-1.amazonaws.com/demo'
  SOCKET_URL = 'wss://01messi3qe.execute-api.us-east-1.amazonaws.com/demo'
} else if (isStag) {
  DASHBOARD_PLOTY_URL = 'https://dashboard.ncall.io/ncall-analytics'
  PUBLIC_URL = 'https://api.cert.ncall.io'
  SOCKET_URL = 'wss://vo5f0zz45l.execute-api.us-east-1.amazonaws.com/qa'
} else {
  DASHBOARD_PLOTY_URL = 'https://dashboard.ncall.io/ncall-analytics'
  PUBLIC_URL = 'https://api.dev.ncall.io'
  SOCKET_URL = 'wss://puixduivsl.execute-api.us-east-1.amazonaws.com/dev'
}

  // // by commenting below the default env would be DEMO
  // DASHBOARD_PLOTY_URL = 'https://dashboard.ncall.io/ncall-analytics'
  // PUBLIC_URL = 'https://rxfblp2ncc.execute-api.us-east-1.amazonaws.com/dev'
  // SOCKET_URL = 'wss://0t6dffe855.execute-api.us-east-1.amazonaws.com/dev'

export {
  PUBLIC_URL,
  SOCKET_URL,
  firebaseConfig,
  vapidKey,
  ENV,
  QUICKSIGHT_URL,
  DASHBOARD_PLOTY_URL
}
