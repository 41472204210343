import React, { useEffect, useState, createContext } from 'react'
import {
  setFilters
} from 'store/reducers/incidents.reducer'
import moment from 'moment-timezone'
import { VIEW } from '../../store/store.types'

export const RootContext = createContext({
  authenticated: false,
  user: {name: null, email: null},
  incidentTimezone: '',
  setAuthenticated: () => {
  },
  setUser: () => {
  },
  setIncidentTimezone: () => {

  }
})

interface UserI {
  email: string | null,
  name: string | null
}

export type initialAuthValue = {
  authenticated: boolean,
  user: UserI,
  setAuthenticated: () => void,
  setUser: () => void
}

export default ({children}: any) => {

  const prevAuth = window.localStorage.getItem('auth') || false
  const prevUser = window.localStorage.getItem('user') || '{}'
  const tz = window.localStorage.getItem('incidentTimezone') || moment.tz.guess()

  const [authenticated, setAuthenticated] = useState(prevAuth)
  const [user, setUser] = useState(JSON.parse(prevUser))
  const [incidentTimezone, setIncidentTimezone] = useState(tz)
  const [incidentView, setIncidentView] = useState(VIEW.LIST)

  const logout = () => {
    setAuthenticated(false)
    setUser({})
    setIncidentTimezone('')
    localStorage.removeItem('id_token')
    window.location.pathname = '/login'
  }

  useEffect(
    () => {
      window.localStorage.setItem('authenticated', authenticated.toString())
      window.localStorage.setItem('user', JSON.stringify(user))
      if (user.email) {
        setFilters({
          userlogged: user.email
        })
      }
    }, [authenticated, user])

  useEffect(() => {
    window.localStorage.setItem('incidentTimezone', incidentTimezone)
  }, [incidentTimezone])

  useEffect(() => {
    window.localStorage.setItem('incidentTimezone', user.time_zone)
    setIncidentTimezone(user.time_zone)
  }, [user.time_zone])

  const defaultContext: any = {
    authenticated,
    setAuthenticated,
    user,
    setUser,
    logout,
    incidentTimezone,
    setIncidentTimezone,
    incidentView,
    setIncidentView
  }

  return (
    <RootContext.Provider value={defaultContext}>
      {children}
    </RootContext.Provider>
  )
}
