import React from 'react'
import cx from 'classnames'
import styled from 'styled-components'
import './tabs.css'

const ActiveLinkLine = styled.span<{ active: boolean }>`
  width: 3px;
  height: 45PX;
  border-radius: 4px;
  background-color: ${({active}) => active ? '#0071ea' : 'transparent'};
  margin-right: 15px;
`
const HActiveLinkLine = styled.span`
  width: 100%;
  height: 3px;
  display: flex;
  background: blue;
  margin-top: 12px;
  margin-left: 5px;
  justify-content: center;
`

const VerticalTabContent = styled.span`
  display: flex;
  align-items: center;
`
const HTabContent = styled.span`
  display: flex;
  align-items: center;
`

interface TabData {
  key: string;
  title: string;
  icon?: React.ReactNode;
  className?: string
}

interface TabsProps {
  data: TabData[];
  hide?: string
  className?: string
  onActiveClick?: () => void
  rightClassname?: string
  right?: React.ReactElement
  defaultActive?: string;
  onTabChange: (tabName: string) => void;
  includeCount?: boolean
  vertical?: boolean
  horizontal?: boolean
}

const Tabs: React.FC<TabsProps> = (props) => {

  const {
    data, hide, right, onTabChange, rightClassname = '', vertical = false,
    horizontal = false, defaultActive, includeCount, className, onActiveClick = () => {
    }
  } = props

  const [active, setActive] = React.useState(defaultActive)

  const handleTabChange = (name: string) => {

    if (name === active && onActiveClick) {
      onActiveClick()
    }

    if (name !== active) {
      setActive(name)
      onTabChange(name)
    }
  }

  const renderHorizontalListItems = () => {
    return data.map(t => {
      if (hide && hide === t.key) {
        return null
      }
      return (
        <li
          onClick={() => handleTabChange(t.key)}
          className={`${t.key === active ? 'active' : ''}`}
          key={t.key}
        >
          <div className=''>
            <HTabContent>
              {t.icon && t.icon}&nbsp;&nbsp;
              {renderTitle(t, true)}
            </HTabContent>
            {t.key === active && <HActiveLinkLine/>}
          </div>
        </li>
      )
    })
  }

  const renderVerticalListItems = () => {

    return data.map(t => {
      if (hide && hide === t.key) {
        return null
      }
      return (
        <li
          onClick={() => handleTabChange(t.key)}
          className={`${t.key === active ? 'active' : ''}`}
          key={t.key}
        >
          <ActiveLinkLine active={t.key === active}/>
          <div className='vertical-tab-right'>
            <VerticalTabContent>
              {t.icon && t.icon}&nbsp;&nbsp;
              {renderTitle(t)}
            </VerticalTabContent>
          </div>
        </li>
      )
    })
  }


  React.useEffect(() => {
    setActive(defaultActive)
  }, [defaultActive])

  const renderTitle = (t: any, isHorizontal?: boolean) => {
    if (includeCount && t.count !== undefined && isHorizontal) {
      return (
        <span>
        {t.title}
          <span
            className={cx('h-tab-count', t.key.toLowerCase(),
              {active: active === t.key},
            )}
          >{t.count}
          </span>
      </span>
      )
    } else if (includeCount && t.count !== undefined)
      return `${t.title} (${t.count || 0})`
    else
      return t.title
  }

  if (vertical) {
    return (
      <div className='nc-tabs-vertical'>
        <ul>
          {renderVerticalListItems()}
        </ul>
      </div>
    )
  }

  if (horizontal) {
    return (
      <div className='nc-tabs-horizontal'>
        <ul>
          {renderHorizontalListItems()}
        </ul>
        {right && (
          <div className={rightClassname}>
            {right}
          </div>
        )}
      </div>
    )
  }

  return (
    <div className={`nc-tabs ${className || ''}`}>
      <ul className='tabs'>
        {
          data.map(t => {

            if (hide && hide === t.key) {
              return null
            }

            return (
              <li onClick={() => handleTabChange(t.key)} className={`${t.key === active ? 'active' : ''}`} key={t.key}>
                <span>
                  {t.icon ?
                    t.icon :
                    <span className={`${t.className}`}/>
                  }
                  {renderTitle(t)}
                </span>
              </li>
            )
          })
        }
      </ul>
      {right && (
        <div className={rightClassname}>
          {right}
        </div>
      )}

    </div>
  )
}

export default Tabs
