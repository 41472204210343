import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _remove from 'lodash/remove'
import _orderBy from 'lodash/orderBy'
import { ClientsI, IncidentsI, UsersI } from '../store.types'
import { mergeSelectedIncidents, removeIncidentsByNumber, removeSelectedIncidents } from './utils'
// import { fetchIncidents } from 'services/incidents.service'SSS

const INCIDENT_TABS = [
  {title: 'Open', className: 'red', key: 'open', count: 0},
  {title: 'Triggered', className: 'yellow', key: 'triggered', count: 0},
  {title: 'Acknowledged', className: 'skyblue', key: 'acknowledged', count: 0},
  {title: 'Resolved', className: 'green', key: 'resolved'},
  {title: 'All Status', className: 'grey', key: 'any status'}
]

export interface IMyOpenIncidentSocket {
  type: string
  incident_number: string
  action: 'add' | 'remove' | 'change'
  status: string
  created_at: string
}

export type IncidentsListsState = {
  incidents: IncidentsI[];
  currentRequestId?: string,
  error: any
  tabs: any[]
  selectedIncidents: IncidentsI[];
  my_open_incidents: IncidentsI[],
  open_incident_page_id: number,
  loading: string,
  hasNext: boolean  
  all_acknowledged_count: number
  all_triggered_count: number  
  open_count: number
  resolved_count: number  
  your_acknowledged_count: number
  your_triggered_count: number
  total: number,
  sorted_type: string
  sorted_by: string
  clients: ClientsI[]
  users: UsersI[]
  filters: FiltersI
  needRefresh: boolean,
  backgroundProcess: {
    show: boolean
    text: string
  },
  reload: boolean
}

interface FiltersI {
  status: string
  search: string
  urgency: string
  priority: string
  severity: string
  assigned_to: string
  has_notes: string
  clients_id: string
  userlogged: string
  page_id: number
  items: number
  sorted_type: string
  sorted_by: string
}

let initialState: IncidentsListsState = {
  incidents: [],
  currentRequestId: undefined,
  error: null,
  tabs: INCIDENT_TABS,
  hasNext: true,
  selectedIncidents: [],
  my_open_incidents: [],
  open_incident_page_id: 1,
  loading: 'idle',
  total: 0,
  all_acknowledged_count: 0,
  all_triggered_count: 0,
  open_count: 0,
  resolved_count: 0,
  your_acknowledged_count: 0,
  your_triggered_count: 0,
  clients: [],
  users: [],
  sorted_type: '',
  sorted_by: '',
  filters: {
    status: 'open',
    search: '',
    urgency: '',
    priority: '',
    has_notes: '',
    severity: '',
    assigned_to: 'all',
    clients_id: '[]',
    userlogged: '',
    page_id: 1,
    sorted_by: '',
    sorted_type: '',
    items: 0
  },
  needRefresh: false,
  backgroundProcess: {
    show: false,
    text: ''
  },
  reload: false
}

export const required_params = {
  status: 'open',
  search: '',
  urgency: '',
  priority: '',
  severity: '',
  assigned_to: 'all',
  clients_id: '[]',
  userlogged: '',
  page_id: 1,
  sorted_by: '',
  sorted_type: '',
  items: 50,
  has_notes: ''
}

export const client_params = {
  status: 'any status',
  search: '',
  urgency: '',
  priority: '',
  severity: '',
  assigned_to: 'all',
  clients_id: '[]',
  userlogged: '',
  page_id: 1,
  sorted_by: '',
  sorted_type: '',
  items: 50,
  has_notes: ''
}

// export const fetchIncidentsByFilters = createAsyncThunk(
//   'incidents/fetchByIncidentsFilters',
//   async (params, {getState, requestId}) => {
//     // @ts-ignore
//     const {currentRequestId, loading} = getState().users
//     if (loading !== 'pending' || requestId !== currentRequestId) {
//       return
//     }
//     const response = await fetchIncidents(params)
//     return response.data
//   }
// )

interface IselectIncidentParam {
  data: IncidentsI
  checked: boolean
}

const IncidentsListSlice = createSlice({
  name: 'incidents',
  initialState,
  reducers: {
    setIncidents(state, action: PayloadAction<any>) {
      const {  incidents, has_next, acknowledged_incidents_count, open_incidents_count, triggered_incidents_count  } = action.payload
      state.incidents = incidents
      state.hasNext = has_next
      // set count of tabs

      state.tabs = state.tabs.map((t) => {
        switch (t.key) {
          case 'open':
            return {...t, count: open_incidents_count}
          case 'acknowledged':
            return {...t, count: acknowledged_incidents_count}
          case 'triggered':
            return {...t, count: triggered_incidents_count}
          default:
            return t
        }
      })
    },
    setCounts(state, action: PayloadAction<any>) {
      const {
        all_acknowledged = 0,
        all_triggered = 0,
        your_acknowledged = 0,
        your_triggered = 0
      } = action.payload
      state.all_acknowledged_count = all_acknowledged
      state.all_triggered_count = all_triggered
      state.your_acknowledged_count = your_acknowledged
      state.your_triggered_count = your_triggered
    },
    selectAllIncidents(state, action: PayloadAction<IncidentsI[]>) {
      state.selectedIncidents = mergeSelectedIncidents(state.selectedIncidents, action.payload)
    },
    deselectAllIncidents(state, action: PayloadAction) {
      state.selectedIncidents = []
    },
    removeAllSelectedIncidents(state, action: PayloadAction<[]>) {
      state.selectedIncidents = []
    },
    removeSelectIncidentsByNumber(state, action: PayloadAction<string[]>) {
      state.selectedIncidents = removeIncidentsByNumber(state.selectedIncidents, action.payload) || []
    },
    setClients(state, action: PayloadAction<ClientsI[]>) {
      state.clients = action.payload
    },
    setUsers(state, action: PayloadAction<UsersI[]>) {
      state.users = action.payload
    },
    setFilters(state, action: PayloadAction<any>) {
      state.filters = {
        ...state.filters,
        ...action.payload
      }
    },
    selectIncident(state, action: PayloadAction<IselectIncidentParam>) {
      const {checked, data} = action.payload
      if (checked) {
        state.selectedIncidents = mergeSelectedIncidents(state.selectedIncidents, [data])
      } else {
        let arr = state.selectedIncidents.slice()
        _remove(arr, o => o.number === data.number)
        state.selectedIncidents = arr
      }
    },
    onApplySorting(state, action: PayloadAction<{ key: string, orderBy: 'asc' | 'desc' }>) {
      const {key, orderBy} = action.payload

      state.sorted_by = key
      state.sorted_type = orderBy

      if(key === 'title') {
        state.incidents =
        _orderBy(state.incidents, ['number'], [orderBy])
        return;
      }

      if(key === 'assigned_to') {
        state.incidents =
        _orderBy(state.incidents, [i => i.assigned_to?.join(',')?.toLowerCase()], [orderBy])
        return;
      }

      if(key === 'client_name') {
        state.incidents =
        _orderBy(state.incidents, [i => i.client_name?.toLowerCase()], [orderBy])
        return;
      }

      state.incidents =
        _orderBy(state.incidents, [key], [orderBy])
    },
    onClearSorting(state, action: PayloadAction<null>) {
      state.sorted_by = ''
      state.sorted_type = ''
    },
    setNeedRefresh(state, action: PayloadAction<boolean>) {
      state.needRefresh = action.payload
    },
    setReload(state, action: PayloadAction<boolean>) {
      state.reload = action.payload
    },
    updateSelectedIncidents(state, action: PayloadAction<IncidentsI[]>) {
      // update selected incident lists.
      const newList = state.selectedIncidents.slice()
      if (action.payload.length === 0) {
        state.selectedIncidents = []
      } else {
        state.selectedIncidents = newList.filter(
          (i) => action.payload.findIndex(o => o.number === i.number) > -1)
      }
    },
    setMyOpenIncidents(state, action: PayloadAction<any[]>) {
      state.my_open_incidents = action.payload
    },
    addMyOpenIncidents(state, action: PayloadAction<any[]>) {
      state.my_open_incidents = [ ...state.my_open_incidents, ...action.payload]
    },
    incrementOpenIncidentPageid(state, action: PayloadAction) {
      state.open_incident_page_id = state.open_incident_page_id + 1
    },
    setInitialPageIdForOpenIncident(state, action: PayloadAction) {
      state.open_incident_page_id = 1
    },
    selectAllMyOpenIncidents(state, action: PayloadAction) {
      state.selectedIncidents = [...state.my_open_incidents]
    },
    deSelectMyOpenIncidents(state, action: PayloadAction) {
      /// do something
      state.selectedIncidents =
        removeSelectedIncidents(state.selectedIncidents, state.my_open_incidents)
    },
    updateMyOpenIncidents(state, action: PayloadAction<any>) {
      // socket updation
      const {
        action: socket_action, type, incident_number, created_at, status, ...rest
      } = action.payload
      switch (socket_action) {
        case 'add':
          console.log('add action')
          state.my_open_incidents = [{
            incident_status: status,
            incident_number: incident_number,
            incident_created_at: created_at,
            ...rest
          }, ...state.my_open_incidents]
          return
        case 'remove':
          const index =
            state.my_open_incidents.findIndex(o => o.number === incident_number)
          if (index > -1) {
            const tempData = [...state.my_open_incidents]
            tempData.splice(index, 1)
            state.my_open_incidents = tempData
          }
          console.log('Remove action')
          return
        case 'change':
          const ind = state.my_open_incidents.findIndex(o => o.number === incident_number)
          if (ind > -1) {
            const tempData = [...state.my_open_incidents]
            let record = tempData[ind]
            record.status = status
            tempData.splice(ind, 1, record)
            state.my_open_incidents = tempData
          }
          console.log('change action')
          return
        default:
          console.error('different action has been called for My open incidents')
          return
      }
    },
    updateAcknowledgeStatus(state, action: PayloadAction<string>) {
      const ind = state.my_open_incidents.findIndex(o => o.number === action.payload)
      if (ind > -1) {
        const tempData = [...state.my_open_incidents]
        tempData[ind].status = 'Acknowledged'
        state.my_open_incidents = tempData
      }
    }
  }
})

export const {
  setIncidents,
  selectIncident,
  selectAllIncidents,
  setClients,
  setUsers,
  setFilters,
  deselectAllIncidents,
  removeAllSelectedIncidents,
  removeSelectIncidentsByNumber,
  onApplySorting,
  onClearSorting,
  setNeedRefresh,
  setReload,
  setCounts,
  updateSelectedIncidents,
  setMyOpenIncidents,
  selectAllMyOpenIncidents,
  deSelectMyOpenIncidents,
  updateMyOpenIncidents,
  addMyOpenIncidents,
  updateAcknowledgeStatus,
  incrementOpenIncidentPageid,
  setInitialPageIdForOpenIncident
} = IncidentsListSlice.actions

export default IncidentsListSlice.reducer

